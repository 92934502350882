import Home from "../Views/Home";

import System from "../Views/System";
import Area from "../Views/Area";
import Device from "../Views/Device";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import TicketReport from "../Views/Report/TicketReport";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import UploadManage from "../Views/Device/UploadManage";
import ParkingRevise from "../Views/Report/ParkingRevise";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UserRole from '../Views/User/UserRole';
import User from '../Views/User/User';

import DeviceList from '../Views/Device/DeviceList';

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    icon: DashboardIcon,
    exact: true,
  },
  {
    
    name: "user",
    icon: PersonIcon,
    subroutes: [
      {
        path: "/UserRole",
        name: "user_role",
        component: UserRole,
        icon: GroupWorkIcon,
        parent: "user",
      },
      {
        path: "/User",
        name: "user_manage",
        component: User,
        icon: AccountCircleIcon,
        parent: "user",
      },
    ],
  },

  {
    name: "area",
    path: "/area",
    icon: AddLocationIcon,
    component: Area,
  },
  {
    name: "device",
    icon: DeviceHubIcon,
    subroutes: [
      {
        path: "/device",
        name: "device_manage",
        component: Device,
        icon: DevicesOtherIcon,
        parent: "device",
      },
      {
        path: "/upload",
        name: "upload_manage",
        component: UploadManage,
        icon: CloudUploadIcon,
        parent: "device",
      },
      {
        path : "/device_list",
        name : "device_list",
        component : DeviceList,
        icon : DevicesOtherIcon,
        parent : "device"
      }
    ],
  },
  {
    name: "report",
    icon: AssessmentIcon,
    subroutes: [
      {
        path: "/ticket_search",
        name: "ticket_search",
        component: TicketReport,
        icon: AssessmentIcon,
        parent: "report",
      },
    ],
  },
  // {
  //   name: "parking",
  //   icon: LocalParkingIcon,
  //   subroutes: [
  //     // {
  //     //   path: "/parking_manage",
  //     //   name: "parking_manage",
  //     //   component: Device,
  //     //   icon: DevicesOtherIcon,
  //     //   parent: "parking",
  //     // },
  //     {
  //       path: "/parking_revise",
  //       name: "parking_revise",
  //       component: ParkingRevise,
  //       icon: DevicesOtherIcon,
  //       parent: "parking",
  //     }
  //   ],
  // },
  {
    path: "/system",
    name: "system",
    component: System,
    icon: SettingsIcon,
  },
];

export default routes;
