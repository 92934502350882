import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/parkingmeter";

export const getParkingMeterByLocationIds = (locationId) => {
  let ids = "";
  if (Array.isArray(locationId)) {
    ids = locationId.map((id) => `ids=${id}`).join("&");
  } else {
    ids = `ids=${locationId}`;
  }
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/parkinglots?${ids}`);
};

export const getParkingMeters = (skip = 0, amount = 10) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}?withrelation=true&skip=${skip}&amount=${amount}`);
};

export const getParkingMeterById = (parkingMeterId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/${parkingMeterId}?withrrelation=true`
  );
};

export const addParkingMeter = (parkingMeterData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, parkingMeterData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateParkingMeter = (parkingMeterId, parkingMeterData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(`${ROOT_URL}${BASE_URL}/${parkingMeterId}`, parkingMeterData)
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteParkingMeter = (parkingMeterId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${parkingMeterId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};

export const addParkingMeterMappingPile = (parkingMeterId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/mapping/${parkingMeterId}`, data)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const addParkingMeterAttribute = (data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/attribute`, data)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const deleteParkingMeterAttribute = (attrId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/attribute/${attrId}`)
      .then((response) => {
        console.log("刪除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("刪除失败", error);
        reject(error);
      });
  });
};

export const updateParkingMeterAttribute = (attrId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(`${ROOT_URL}${BASE_URL}/attribute/${attrId}`, data)
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const getParkingMeterAttributes = (meterId, name) => {
  const url = `${ROOT_URL}${BASE_URL}/attribute?param[parkingMeterId]=${meterId}&param[name]=${name}`;
  return apiProvider.get(url);
};

export const distributeDeviceFile = (data) => {
  // console.log(data);

  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/distributeDeviceFile`, data)
      .then((response) => {
        // console.log(response);
        if (response.data.data.result) {
          console.log("新增成功", response.data);
          resolve(response.data);
        }
        else {
          reject(response.data);
        }
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};
