import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/parkingspace";

export const getAllParkingSpaces = (skip =0, amount=10) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}?withrelation=true&skip=${skip}&amount=${amount}`);
};




export const getParkingSpaces = (ParkingLotId, skip=0, amount=10) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?param[parkingLotId]=${ParkingLotId}&withrelation=true&skip=${skip}&amount=${amount}`
  );
};

export const getParkingSpace = (ParkingSpaceId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${ParkingSpaceId}`);
};

export const addParkingSpace = (parkingSpaceData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, parkingSpaceData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const addParkingSpaces = (parkingSpaceDatas) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/batch`, parkingSpaceDatas)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateParkingSpace = (parkingSpaceData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/${parkingSpaceData.parkingSpaceId}`,
        parkingSpaceData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteParkingSpace = (parkingSpaceId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${parkingSpaceId}`, data)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};

export const AddParkingSpaceMappingWithPile = (parkingSpaceId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/mapping/${parkingSpaceId}`, data)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const AddParkingSpaceMappingsWithPile = (parkingSpaceId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/mappings/${parkingSpaceId}`, data)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const GetParkingSpaceMappingWithPile = (parkingSpaceId) => {
  console.log(`${ROOT_URL}${BASE_URL}/mapping/${parkingSpaceId}`);
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/mapping/${parkingSpaceId}`);
};

export const UpdateParkingSpaceMappingWithPile = (parkingSpaceId, data) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(`${ROOT_URL}${BASE_URL}/mapping/${parkingSpaceId}`, data)
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const DeleteParkingSpaceMappingWithPile = (parkingSpaceId, data) => {
  const headers = {
    "Content-Type": `application/json`,
  };

  console.log("DeleteParkingSpaceMappingWithPile: data:" + data);

  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/mapping/${parkingSpaceId}`, {
        headers,
        data: JSON.stringify(data),
      })
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};

export const addParkingSpaceAttr = (parkingSpaceAttrData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/attribute`, parkingSpaceAttrData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.log(parkingSpaceAttrData);
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateParkingSpaceAttr = (parkingSpaceAttrData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/attribute/${parkingSpaceAttrData.parkingSpaceAttributeId}`,
        parkingSpaceAttrData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteParkingSpaceAttr = (parkingSpaceAttributeId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/attribute/${parkingSpaceAttributeId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};

export const getParkingSpaceSnapshotBySpaceId = (parkingSpaceId, start = null, end = null) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/snapshot/${parkingSpaceId}?start=${start}&end=${end}`);
}

