import { jwtDecode } from "jwt-decode";


let token = localStorage.getItem("token");
let keep = localStorage.getItem("keep") === "1";
let decodeToken = token ? jwtDecode(token) : null;

const tokenIsValid = () => {
  if (!token || !decodeToken) {
    return false;
  }

  // check if token is expired
  if (new Date() > new Date(decodeToken.exp * 1000)) {
    return false;
  } else return true;
};

const setToken = (newToken) => {
  console.log("setToken", newToken);
  token = newToken;
  decodeToken = newToken ? jwtDecode(newToken) : null;
  localStorage.setItem("token", newToken);

  // null means logout, clear related data
  if (newToken === null) {
    localStorage.removeItem("token");
  }
};

const setKeep = (newKeep) => {
  console.log("setKeep", newKeep);
  keep = newKeep;
  localStorage.setItem("keep", newKeep ? 1 : 0);

  if (newKeep === false || newKeep === null) {
    localStorage.removeItem("keep");
  }
};

export { token, keep, tokenIsValid, setToken, setKeep };
