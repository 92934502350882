import React, { useEffect , useContext} from "react";
import AppRouter from "./routers/AppRouter.js";
import theme from "./customTheme.js";
import { ThemeProvider } from "@material-ui/styles";
import { AuthProvider ,AuthContext, useAuth } from "./contexts/AuthContext";
import { GlobalProvider } from "./contexts/GlobalContext";
import "./style/normalize.css";
import { signalRConnection, createSignalRConnection } from './utils/signalrService.js';
import {tokenIsValid} from './utils/tokenHandler.js'

const App = () => {

  // const authContext = useContext(AuthContext);
  // const { tokenState } = authContext || {};

  // useEffect(()=>{
  //   console.log("Token value:", tokenState);
  //   if (tokenState   && !signalRConnection) {
  //     console.log("APP useEffect");
  //     createSignalRConnection();
  //   }
  // },[tokenState]);

  useEffect(() => {
    // 檢查 Token 是否有效，並且建立 SignalR 連接
    const checkTokenAndConnectSignalR = async () => { 
      if (tokenIsValid()) {
        // 如果 Token 有效，建立 SignalR 連接
        createSignalRConnection();
      }
    };

    checkTokenAndConnectSignalR();
  }, []); // 只在應用程式啟動時執行一次

  useEffect(()=>{
    if(signalRConnection)
    {
      signalRConnection.on("OnParkingTicketEventArrive", (user, message) =>{
        console.log(`${user}: ${message}`);
         // 在這裡處理接收到的訊息
      });
  
      return ()=>{
           // 在組件卸載時移除事件處理程序
           signalRConnection.off("OnParkingTicketEventArrive");
      }
    }    
  },[signalRConnection]);// 確保這個效果只運行一次




 return (
 <GlobalProvider>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </AuthProvider>
  </GlobalProvider>
 );
}

export default App;
