import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/parkingTicket";

export const getParkingTickets = (parkingspaceId, skip = 0, amount = 10) => {
  if (parkingspaceId.trim() === "")
    return apiProvider.get(
      `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}`
    );
  else
    return apiProvider.get(
      `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}&param[parkingSpaceId]=${parkingspaceId}`
    );
};

export const getParkingTicketsByIds = ( parkingspaceIds,skip = 0,amount = 10) => {
  
    // 將陣列轉換為逗號分隔的字符串
    const parkingSpaceIdsParam = parkingspaceIds.join(",");
    return apiProvider.get(
      `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}&param[parkingSpaceId]=${parkingSpaceIdsParam}`
    );
  
};

export const getParkingTicketById = (parkingTicketId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingTicketId}`);
};

export const getParkingTicketHistoriesById = (parkingTicketId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingTicketId}/histories`);
};

export const getParkingTicketSnapshotList = (parkingTicketId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingTicketId}/snapshots`);
};
