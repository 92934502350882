import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/user";



export const getUsers = (skip=0, amount=10, withRelation = true) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}&withRelation=${withRelation}`
  );
};

export const getUserById = (userId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${userId}`);
};

export const addUser = (userData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, userData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateUser = (userId,userData) => {

  
  
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/${userId}`,
        userData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteUser = (userId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${userId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        console.log(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};


export const getUserAttributes = ( userId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/attribute?param[userId]=${userId}`
  );
};

export const getUserAttributeById = ( attrId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/attribute/${attrId}`
  );
};



export const addUserAttrbute = (attrData) => {

 console.log(attrData);

  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/attribute`, attrData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};


export const updateUserAttribute = (attrId,attrData) => {

  
  
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/attribute/${attrId}`,
        attrData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteUserAttribute = (attrId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/attribute/${attrId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        console.log(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};