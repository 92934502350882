import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import defaulttheme from "../../customTheme.js";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {

  getParkingSpaces,
  addParkingSpace,
  updateParkingSpace,
  deleteParkingSpace,
  addParkingSpaces,
} from "../../utils/ParkingSpaceApi";
import {
  GetParkingSpaceMappingWithPile,
  AddParkingSpaceMappingsWithPile,
  DeleteParkingSpaceMappingWithPile,
} from "../../utils/ParkingSpaceApi";
import {
  addParkingSpaceAttr,
  updateParkingSpaceAttr,
  deleteParkingSpaceAttr,
} from "../../utils/ParkingSpaceApi";
import {
  getParkingPileById,
  getParkingPiles,
} from "../../utils/ParkingPileApi";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DescriptionIcon from "@material-ui/icons/Description";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";
import Pagination from '@material-ui/lab/Pagination';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  button: {
    marginLeft: "5px",
    marginRight: "5px",
  },

  tableRow: {
    fontSize: "16px",
  },
}));

function ParkingSpace({ parkingLot }) {
  const classes = useStyles();
  const { showSnackbar } = useContext(GlobalContext);
  // const { token } = useContext(AuthContext); // 取得Token
  const [selectedParkingLot, setSelectedParkingLot] = React.useState(null);
  const [parkingSpaceData, setParkingSpaceData] = React.useState(null);
  const [selectedParkingSpace, setSelectedParkingSpace] = React.useState(null);
  const [selectParkingSpaceWithPile, setSelectedParkingSpaceWithPile] =
    React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openBatch, setBatchOpen] = React.useState(false);
  const [open_edit, setOpenEdit] = React.useState(false);
  const [inputName, setInputName] = React.useState("");
  const [inputDescription, setInputDescription] = React.useState("");
  const [enable, setEnable] = React.useState(true);
  const [openAddAttr, setOpenAddAttr] = React.useState(false);
  const [inputAttrName, setAttrName] = React.useState("");
  const [inputAttrContent, setAttrContent] = React.useState("");
  const [attrEnable, setAttrEnable] = React.useState(true);
  const [openEditAttr, setOpenEditAttr] = React.useState(false);
  const [selectedAttr, setSelectedAttr] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [prefixName, setPrefixName] = React.useState("");
  const [startNum, setStartNum] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const [attrFrom, setAttrFrom] = React.useState("");
  const [openAddMapping, setOpenAddMapping] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [parkingPileData, setParkingPile] = React.useState([]);
  const [pileTypes, setPileTypes] = React.useState([]);
  const [page, setPage] = React.useState(1); // 初始頁碼為1
  const [total, setTotal] = React.useState(0);
  const rowsPerPage = 10;



  useEffect(() => {
    // 在組件加載的時候得到資料
    if (parkingLot) {
      RefreshParkingSpace();
      RefreshParkingPile();
      setSelectedParkingLot(parkingLot);
    } else {
      showSnackbar({
        message: "請先選擇路段",
        severity: "error",
      });
    }
  }, []);

  const RefreshParkingPile = async () => {
    try {
      const response = await getParkingPiles(0, 200);
      const pileDatas = response.data.data;

      setParkingPile(pileDatas);
    } catch (error) {
      console.error("query fail", error);
    }
  };

  const RefreshParkingSpace = async (newPage = 1) => {
    try {

      const response = await getParkingSpaces(parkingLot.parkingLotId, rowsPerPage * (newPage - 1), rowsPerPage);
      const parkingSpaceData = response.data.data;
      setTotal(response.data.total);
      const parkingSpaceDataWithMappingPile = await Promise.all(
        parkingSpaceData.map(async (data) => {
          const pileData = await GetMappingPile(data.parkingSpaceId);
          return {
            parkingSpace: data,
            pileData: pileData,
          };
        })
      );

      setParkingSpaceData(parkingSpaceDataWithMappingPile);
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const GetMappingPile = async (spaceId) => {
    try {
      const response = await GetParkingSpaceMappingWithPile(spaceId);
      const mappingPile = response.data.data;
      return mappingPile;
    } catch (error) {
      console.error("query fail", error);
      return null; // 或者根据需求返回适当的默认值
    }
  };

  // const RefreshParkingSpace = () =>{
  //   getParkingSpaces(ApiHost, token, parkingLot.parkingLotId)
  //   .then((response)=>{
  //       const parkingSpaceData = response.data.data;

  //       const parkingSpaceDataWithMappingPile = parkingSpaceData.map((data) => ({
  //         parkingSpace: data,
  //         pileData: GetMappingPile(data.parkingSpaceId),
  //       }));

  //       console.log(parkingSpaceDataWithMappingPile);
  //       setParkingSpaceData(parkingSpaceDataWithMappingPile);
  //     })
  //     .catch((error) =>{
  //       console.error('query fail', error);
  //       showSnackbar({
  //         message: "取得資料失敗",
  //         severity: "error"
  //      });
  //     });
  // }

  // const GetMappingPile=(spaceId)=>{
  //   GetParkingSpaceMappingWithPile(ApiHost,token,spaceId)
  //   .then((response)=>{
  //     const mappingPile = response.data.data;
  //     console.log(mappingPile);
  //     return mappingPile;
  //   })
  //   .catch((error)=>{
  //     console.error('query fail', error);
  //   });
  // }

  const handleRowClick = (data) => {
    setSelectedParkingSpace(data.parkingSpace);
    setSelectedParkingSpaceWithPile(data);
    setInputName(data.name);
    setInputDescription(data.description);
    setEnable(data.isEnabled);
  };

  const handleAddClick = (event) => {
    setSelectedParkingSpace(null);
    setSelectedParkingSpaceWithPile(null);
    //setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeEnable = (isEnabled) => {
    setEnable(isEnabled);
  };

  const handleSave = () => {
    addParkingSpace({
      parkingLotId: selectedParkingLot.parkingLotId,
      name: inputName,
      description: inputDescription,
      isEnabled: enable,
    })
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("query fail", error);
        showSnackbar({
          message: "新增失敗",
          severity: "error",
        });
      });

    setInputDescription(""); // 清空輸入框
    setInputName("");
    handleClose();
  };

  const handleEditClick = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleEdit = () => {
    updateParkingSpace({
      parkingLotId: parkingLot.parkingLotId,
      name: inputName,
      description: inputDescription,
      isEnabled: enable,
      parkingSpaceId: selectedParkingSpace.parkingSpaceId,
    })
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        showSnackbar({
          message: "更新失敗",
          severity: "error",
        });
      });

    setInputDescription(""); // 清空輸入框
    setInputName("");
    handleCloseEdit();
  };

  const handleDeleteClick = () => {
    deleteParkingSpace(selectedParkingSpace.parkingSpaceId)
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("query fail", error);
        showSnackbar({
          message: "刪除失敗",
          severity: "error",
        });
      });
  };

  const handleOpenAddAttr = () => {
    setAnchorE2(null);
    setOpenAddAttr(true);
  };

  const handleAddAttr = () => {
    addParkingSpaceAttr({
      parkingSpaceId: selectedParkingSpace.parkingSpaceId,
      name: inputAttrName,
      content: inputAttrContent,
      isEnabled: attrEnable,
    })
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("Add fail", error);
        showSnackbar({
          message: "新增失敗",
          severity: "error",
        });
      });

    setAttrName(""); // 清空輸入框
    setAttrContent("");
    setAttrEnable(true);
    handleAddAttrClose();
  };

  const handleAddAttrClose = () => {
    setOpenAddAttr(false);
  };

  const handleClickChip = (attr) => {
    setSelectedAttr(attr);
    setAttrName(attr.name);
    setAttrContent(attr.content);
    setAttrEnable(attr.isEnabled);
    setOpenEditAttr(true);
  };

  const handleEditAttr = () => {
    updateParkingSpaceAttr({
      parkingSpaceId: selectedParkingSpace.parkingSpaceId,
      name: inputAttrName,
      content: inputAttrContent,
      isEnabled: attrEnable,
      parkingSpaceAttributeId: selectedAttr.parkingSpaceAttributeId,
    })
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("handleEditAttr fail", error);
        showSnackbar({
          message: "更新失敗",
          severity: "error",
        });
      });

    setAttrName(""); // 清空輸入框
    setAttrContent("");
    setAttrEnable(true);
    handleCloseEditAttr();
  };

  const handleCloseEditAttr = () => {
    setOpenEditAttr(false);
  };

  const handleDeleteChip = (attr) => {
    deleteParkingSpaceAttr(attr.parkingSpaceAttributeId)
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("handleDeleteChip fail", error);
        showSnackbar({
          message: "刪除失敗",
          severity: "error",
        });
      });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAdd = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleMenuBatchAdd = () => {
    setBatchOpen(true);
    setAnchorEl(null);
  };

  const handleBatchClose = () => {
    setStartNum(1);
    setCount(0);
    setPrefixName("");
    setBatchOpen(false);
  };

  const handleSelect = (parkingSpaceId) => {
    setAttrFrom(parkingSpaceId);
  };

  const handleBatchSave = () => {
    let attrs = [];
    const foundParkingSpace = parkingSpaceData.find(
      (space) => space.parkingSpace.parkingSpaceId === attrFrom
    );
    if (foundParkingSpace)
      attrs = foundParkingSpace.parkingSpace.parkingSpaceAttributes;

    let datas = [];
    let endNumber = parseInt(startNum) + parseInt(count);
    for (let i = startNum; i < endNumber; i++) {
      const formattedNumber = i.toString().padStart(3, "0");
      const spaceName = prefixName + formattedNumber;
      const data = {
        parkingLotId: selectedParkingLot.parkingLotId,
        name: spaceName,
        description: spaceName,
        isEnabled: true,
        parkingSpaceAttributes: attrs,
      };
      datas.push(data);
      console.log(` start :  ${startNum} count: ${count}`);
      console.log(`i:${i}, start+count: ${endNumber}`);
    }

    console.log(datas);

    addParkingSpaces(datas)
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("add fail", error);
        showSnackbar({
          message: "新增失敗",
          severity: "error",
        });
      });

    setStartNum(1);
    setCount(0);
    setPrefixName("");
    setAttrFrom("");
    setBatchOpen(false);
  };

  const handleAttrMenuClose = () => {
    setAnchorE2(null);
  };

  const handleAddAttrClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleOpenAddMapping = () => {
    setAnchorE2(null);
    setOpenAddMapping(true);
  };

  const handleCloseAddMapping = () => {
    setOpenAddMapping(false);
  };

  const handleAddMapping = () => {
    let datas = [];
    console.log(pileTypes);
    pileTypes.map((pileType) => {
      const data = {
        parkPileId: pileType.pile.parkPileId,
        mappingType: pileType.type,
      };
      datas.push(data);
    });

    console.log(datas);
    AddParkingSpaceMappingsWithPile(selectedParkingSpace.parkingSpaceId, datas)
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("add fail", error);
        showSnackbar({
          message: "新增失敗",
          severity: "error",
        });
      });

    setOpenAddMapping(false);
    setChecked([]);
    setPileTypes([]);
  };

  const handleToggle = (data) => () => {
    const currentIndex = checked.indexOf(data);
    const newChecked = [...checked];
    const newPileTypes = [...pileTypes];
    if (currentIndex === -1) {
      newChecked.push(data);
      newPileTypes.push({ pile: data, type: "Single" });
    } else {
      newChecked.splice(currentIndex, 1);
      newPileTypes.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setPileTypes(newPileTypes);
  };

  const handleRadioTypeChange = (type, pileId) => {
    //

    const match = checked.find((data) => data.parkPileId === pileId);
    if (match) {
      if (pileTypes.length > 0) {
        const newPileTypes = [...pileTypes];
        const matchPileType = newPileTypes.find(
          (data) => data.pile.parkPileId === pileId
        );
        if (!matchPileType) {
          newPileTypes.push({ pile: match, type: type });
          setPileTypes(newPileTypes);
        } else {
          matchPileType.type = type;
          newPileTypes.splice(pileTypes.indexOf(matchPileType), 1);
          newPileTypes.push({ pile: match, type: type });
          setPileTypes(newPileTypes);
        }
      } else {
        setPileTypes([{ pile: match, type: type }]);
      }
    }
  };

  const handleDeleteMappingChip = (parkingSpaceId, pileId, type) => {
    console.log("parkingSpaceId: " + parkingSpaceId);
    console.log("delete: " + pileId);

    DeleteParkingSpaceMappingWithPile(parkingSpaceId, {
      parkPileId: pileId,
      mappingType: type,
    })
      .then((response) => {
        RefreshParkingSpace();
      })
      .catch((error) => {
        console.error("delete fail", error);
        showSnackbar({
          message: "刪除失敗",
          severity: "error",
        });
      });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    RefreshParkingSpace(newPage);
  };

  const renderMenu = (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuAdd}>Add</MenuItem>
        <MenuItem onClick={handleMenuBatchAdd}>Batch Add</MenuItem>
      </Menu>
    </>
  );

  const renderAttributeMappingMenu = (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorE2}
        keepMounted
        open={Boolean(anchorE2)}
        onClose={handleAttrMenuClose}
      >
        <MenuItem onClick={handleOpenAddAttr}>新增屬性</MenuItem>
        <MenuItem onClick={handleOpenAddMapping}>新增關聯</MenuItem>
      </Menu>
    </>
  );

  const renderButton = (
    <>
      <Button
        variant="contained"
        startIcon={<AddBoxIcon />}
        className={classes.button}
        style={{
          backgroundColor: defaulttheme.buttonColor.green,
          color: "white",
        }}
        onClick={handleAddClick}
      >
        Add
      </Button>

      <Button
        variant="contained"
        startIcon={<UpdateIcon />}
        className={classes.button}
        style={{
          backgroundColor: defaulttheme.buttonColor.grey,
          color: "white",
        }}
        onClick={handleEditClick}
      >
        Edit
      </Button>

      <Button
        variant="contained"
        startIcon={<DeleteIcon />}
        className={classes.button}
        style={{
          backgroundColor: defaulttheme.buttonColor.red,
          color: "white",
        }}
        onClick={handleDeleteClick}
      >
        delete
      </Button>
    </>
  );

  const RenderSearch = (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" style={{ margin: "20px" }}>
            <TextField
              label="關鍵字"
              variant="outlined"
              style={{ borderRadius: "20px", width: "300px" }} //圓角與自動伸縮
            //value={searchQuery}
            //onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              style={{ margin: "10px", borderRadius: "10px" }}
            >
              搜索
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const RenderAddMappingDialog = (
    <Dialog
      open={openAddMapping}
      onClose={handleCloseAddMapping}
      aria-labelledby="addattrform-dialog-title"
    >
      <DialogTitle id="addattrform-dialog-title">新增關聯</DialogTitle>
      <DialogContent>
        <h3> {selectedParkingSpace ? selectedParkingSpace.name : null}</h3>
        {RenderSearch}
        <Paper>
          <List
            component="Paper"
            className={classes.root}
            style={{
              width: "100%",
              maxWidth: 1000,
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {parkingPileData
              ? parkingPileData.map((data, index) => {
                const dataExistsInPileData =
                  selectParkingSpaceWithPile &&
                    selectParkingSpaceWithPile.pileData
                    ? selectParkingSpaceWithPile.pileData.some(
                      (pile) => pile.parkPileId === data.parkPileId
                    )
                    : null;
                if (!dataExistsInPileData) {
                  return (
                    <ListItem
                      key={data.parkPileId}
                      role={undefined}
                      dense
                      button
                      onClick={handleToggle(data)}
                    >
                      <ListItemIcon style={{ margin: "10px" }}>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(data) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            "aria-labelledby": `${data.parkPileId}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={data.parkPileId}
                        primary={data.name}
                      />
                      <ListItemSecondaryAction>
                        {checked.indexOf(data) !== -1 ? (
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="position"
                              name="position"
                              defaultValue="Single"
                            >
                              <FormControlLabel
                                value="Single"
                                control={<Radio color="primary" />}
                                label="Single"
                                labelPlacement="top"
                                onClick={() =>
                                  handleRadioTypeChange(
                                    "Single",
                                    data.parkPileId
                                  )
                                }
                              />
                              <FormControlLabel
                                value="Left"
                                control={<Radio color="primary" />}
                                label="Left"
                                labelPlacement="top"
                                onClick={() =>
                                  handleRadioTypeChange(
                                    "Left",
                                    data.parkPileId
                                  )
                                }
                              />
                              <FormControlLabel
                                value="Right"
                                control={<Radio color="primary" />}
                                label="Right"
                                labelPlacement="top"
                                onClick={() =>
                                  handleRadioTypeChange(
                                    "Right",
                                    data.parkPileId
                                  )
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        ) : null}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })
              : null}
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleAddMapping}>
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseAddMapping}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderAddParkingSpace = (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="addform-dialog-title"
      fullWidth="true"
      maxWidth="sm"
    >
      <DialogTitle id="addform-dialog-title">新增停車格</DialogTitle>
      <DialogContent>
        <form className={classes.formControl}>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="名稱"
              onChange={(e) => setInputName(e.target.value)}
            />
          </div>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="敘述"
              fullWidth={true}
              onChange={(e) => setInputDescription(e.target.value)}
            />
          </div>

          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enable}
                  onChange={(e) => handleChangeEnable(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave}>
          確定
        </Button>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderBatchAddParkingSpace = (
    <Dialog
      open={openBatch}
      onClose={handleBatchClose}
      aria-labelledby="addform-dialog-title"
      fullWidth="true"
      maxWidth="sm"
    >
      <DialogTitle id="addform-dialog-title">批次新增停車格</DialogTitle>
      <DialogContent>
        <form className={classes.formControl}>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="前置名稱"
                  onChange={(e) => setPrefixName(e.target.value)}
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="start"
                  label="開始編號"
                  onChange={(e) => setStartNum(e.target.value)}
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                  type="number"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="count"
                  label="個數"
                  onChange={(e) => setCount(e.target.value)}
                  type="number"
                />
              </Grid>
            </Grid>
          </div>
          <Typography
            component="div"
            style={{ marginTop: "50px", marginBottom: "50px" }}
          >
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>複製屬性來源</Grid>
              <Select
                native
                onChange={(event) => handleSelect(event.target.value)}
                fullWidth={true}
                style={{
                  textAlignLast: "end",
                  paddingRight: "4px",
                }}
              >
                <option key="none" value={null}>
                  無
                </option>
                {parkingSpaceData && parkingSpaceData.parkingSpace
                  ? parkingSpaceData.parkingSpace.map((data) => (
                    <option
                      key={data.parkingSpaceId}
                      value={data.parkingSpaceId}
                    >
                      {data.name}
                    </option>
                  ))
                  : null}
              </Select>
            </Grid>
          </Typography>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleBatchSave}>
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBatchClose}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderEditParkingSpace = (
    <Dialog
      open={open_edit}
      onClose={handleCloseEdit}
      aria-labelledby="addform-dialog-title"
    >
      <DialogTitle id="addform-dialog-title">更新停車格</DialogTitle>
      <DialogContent>
        <h3> {selectedParkingSpace ? selectedParkingSpace.name : null}</h3>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="名稱"
          fullWidth={true}
          onChange={(e) => setInputName(e.target.value)}
          value={inputName}
        />
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="敘述"
          fullWidth={true}
          onChange={(e) => setInputDescription(e.target.value)}
          value={inputDescription}
        />

        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>停用</Grid>
            <Grid item>
              <Switch
                checked={enable}
                onChange={(e) => handleChangeEnable(e.target.checked)}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>啟用</Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleEdit}>
          確定
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCloseEdit}>
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderAddParkingSpaceAttr = (
    <Dialog
      open={openAddAttr}
      onClose={handleAddAttrClose}
      aria-labelledby="addattrform-dialog-title"
    >
      <DialogTitle id="addattrform-dialog-title">新增屬性</DialogTitle>
      <DialogContent>
        <h3> {selectedParkingSpace ? selectedParkingSpace.name : null}</h3>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="名稱"
          fullWidth={true}
          onChange={(e) => setAttrName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="content"
          label="內容"
          fullWidth={true}
          onChange={(e) => setAttrContent(e.target.value)}
        />

        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>停用</Grid>
            <Grid item>
              <Switch
                checked={attrEnable}
                onChange={(e) => setAttrEnable(e.target.checked)}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>啟用</Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleAddAttr}>
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddAttrClose}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  const RenderEditParkingSpaceAttr = (
    <Dialog
      open={openEditAttr}
      onClose={handleCloseEditAttr}
      aria-labelledby="addattrform-dialog-title"
    >
      <DialogTitle id="addattrform-dialog-title">修改屬性</DialogTitle>
      <DialogContent>
        <h3> {selectedParkingSpace ? selectedParkingSpace.name : null}</h3>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="名稱"
          fullWidth={true}
          onChange={(e) => setAttrName(e.target.value)}
          value={inputAttrName}
        />
        <TextField
          margin="dense"
          id="content"
          label="內容"
          fullWidth={true}
          onChange={(e) => setAttrContent(e.target.value)}
          value={inputAttrContent}
        />

        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>停用</Grid>
            <Grid item>
              <Switch
                checked={attrEnable}
                onChange={(e) => setAttrEnable(e.target.checked)}
                name="isEnabled"
                color="primary"
              />
            </Grid>
            <Grid item>啟用</Grid>
          </Grid>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleEditAttr}>
          確定
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseEditAttr}
        >
          取消
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Grid container spacing={3}>
        <h2 style={{ margin: "20px" }}>
          {parkingLot ? parkingLot.name : "尚未選擇路段"}
        </h2>
        <Grid item xs={12}>
          <Paper variant="outlined" style={{ marginTop: "20px" }}>
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={6}>
                <h3 style={{ marginLeft: "10px" }}>停車格管理</h3>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                {renderButton}
              </Grid>
            </Grid>
            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: defaulttheme.blue.delta }}
                  >
                    <TableCell
                      key="area"
                      align="left"
                      style={{
                        color: "white",
                        fontSize: 20,
                        width: "20%",
                        borderBottom: "1px solid white",
                      }}
                    >
                      名稱
                    </TableCell>
                    <TableCell
                      key="parkinglot"
                      align="left"
                      style={{
                        color: "white",
                        fontSize: 20,
                        width: "20%",
                        borderBottom: "1px solid white",
                      }}
                    >
                      敘述
                    </TableCell>
                    <TableCell
                      key="description"
                      align="left"
                      style={{
                        color: "white",
                        fontSize: 20,
                        width: "30%",
                        borderBottom: "1px solid white",
                      }}
                    >
                      附加屬性
                    </TableCell>
                    <TableCell
                      key="mapping"
                      align="left"
                      style={{
                        color: "white",
                        fontSize: 20,
                        width: "20%",
                        borderBottom: "1px solid white",
                      }}
                    >
                      關聯停車柱
                    </TableCell>
                    <TableCell
                      key="button"
                      align="left"
                      style={{
                        color: "white",
                        fontSize: 20,
                        width: "10%",
                        borderBottom: "1px solid white",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parkingSpaceData
                    ? parkingSpaceData.map((data) => (
                      <TableRow
                        key={data.parkingSpace.parkingSpaceId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => handleRowClick(data)}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedParkingSpace &&
                              selectedParkingSpace.parkingSpaceId ===
                              data.parkingSpace.parkingSpaceId
                              ? "#e0f0fb"
                              : "white",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableRow}
                        >
                          {data.parkingSpace.isEnabled
                            ? data.parkingSpace.name
                            : data.parkingSpace.name + " (停用)"}
                        </TableCell>
                        <TableCell align="left" className={classes.tableRow}>
                          {data.parkingSpace.description}
                        </TableCell>
                        <TableCell align="left" className={classes.tableRow}>
                          {data.parkingSpace.parkingSpaceAttributes
                            ? data.parkingSpace.parkingSpaceAttributes.map(
                              (attr) => (
                                <Chip
                                  key={attr.parkingSpaceAttributeId}
                                  icon={<DescriptionIcon />}
                                  label={attr.name}
                                  color={
                                    attr.isEnabled ? "primary" : "default"
                                  }
                                  clickable
                                  onClick={() => handleClickChip(attr)}
                                  onDelete={() => handleDeleteChip(attr)}
                                  style={{ margin: "3px" }}
                                />
                              )
                            )
                            : null}
                        </TableCell>
                        <TableCell align="left" className={classes.tableRow}>
                          {data.pileData
                            ? data.pileData.map((pile) => {
                              const matchPile = parkingPileData.find(
                                (parkingPile) =>
                                  parkingPile.parkPileId === pile.parkPileId
                              );
                              const label = matchPile ? matchPile.name : "";
                              return (
                                <Chip
                                  avatar={
                                    pile.mappingType === "Single" ? (
                                      <Avatar>S</Avatar>
                                    ) : pile.mappingType === "Left" ? (
                                      <Avatar>L</Avatar>
                                    ) : pile.mappingType === "Right" ? (
                                      <Avatar>R</Avatar>
                                    ) : null
                                  }
                                  style={{ margin: "3px" }}
                                  color="secondary"
                                  label={label}
                                  clickable
                                  onDelete={() =>
                                    handleDeleteMappingChip(
                                      data.parkingSpace.parkingSpaceId,
                                      pile.parkPileId,
                                      pile.mappingType
                                    )
                                  }
                                />
                              );
                            })
                            : null}
                        </TableCell>
                        <TableCell>
                          {selectedParkingSpace &&
                            selectedParkingSpace.parkingSpaceId ===
                            data.parkingSpace.parkingSpaceId ? (
                            <Fab
                              size="small"
                              color="primary"
                              aria-label="add"
                              onClick={handleAddAttrClick}
                            >
                              <AddIcon />
                            </Fab>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          count={total > 0 ? Math.ceil(total / rowsPerPage) : 0}
          page={page}
          onChange={(event, newPage) => handleChangePage(newPage)}
        />
      </div>
      {/*新增ParkingSpace*/}
      {RenderAddParkingSpace}
      {/*批次新增ParkingSpace*/}
      {RenderBatchAddParkingSpace}
      {/*修改ParkingSpace*/}
      {RenderEditParkingSpace}
      {/*新增ParkingSpaceAttr*/}
      {RenderAddParkingSpaceAttr}
      {/*修改ParkingSpace Attr*/}
      {RenderEditParkingSpaceAttr}
      {/*新增停車柱關聯*/}
      {RenderAddMappingDialog}

      {renderMenu}
      {renderAttributeMappingMenu}
    </>
  );
}

export default ParkingSpace;
