import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/location";

export const getLocations = (amout = 20, skip = 0, param = {}) => {

  let qs = `amount=${amout}&skip=${skip}`;
  for (const [key, value] of Object.entries(param)) {
    qs += `&${key}=${value}`;
  }

  return apiProvider.get(`${ROOT_URL}${BASE_URL}s?${qs}`);
};

export const getLocationById = (locationId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${locationId}`);
};

export const addLocation = (locationData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, locationData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateLocation = (locationData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(`${ROOT_URL}${BASE_URL}/${locationData.locationId}`, locationData)
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteLocation = (locationId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${locationId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};
