import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ReactImageMagnify from "react-image-magnify";
import { makeStyles } from "@material-ui/core/styles";

import { ROOT_URL } from "../../config/api";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
    },
    paper: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    imageList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        width: "100%",
        height: "100%",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const Page = ({ snapshot }) => {
    const classes = useStyles();
    const [imgData, setImgData] = React.useState([]);
    const ISO8601ToDateTime = (isoDateString) => {
        const date = new Date(isoDateString);

        // 確保Date物件有效，並格式化日期和時間
        if (!isNaN(date.getTime())) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        } else {
            return "Invalid Date";
        }
    }

    useEffect(() => {
        if (snapshot === null || snapshot === '' || snapshot.files === null || snapshot.files === '') {
            return;
        }

        let imgData = snapshot.files.map((item) => {
            return {
                img: `${ROOT_URL}/api/v1/parkingspace/snapshot/file/${item.filename}`,
                time: ISO8601ToDateTime(item.time),
                title: snapshot.createReason,
            }
        });

        setImgData(imgData);

    }, [snapshot]);

    return (
        <Box>
            <Card variant="outlined">
                <CardContent>
                    <Typography color="textSecondary">
                        {ISO8601ToDateTime(snapshot.createAt)} - {snapshot.createReason}
                    </Typography>
                    <Typography color="textSecondary">
                        {`來源: ${snapshot.recordFor} Id: ${snapshot.recordId}`}
                    </Typography>
                    <ImageList cols={1} className={classes.imageList}>
                        {imgData
                            ? imgData.map((item) => (
                                <ImageListItem key={item.img} style={{ height: "auto" }}>
                                    {
                                        /* <img src={item.img} alt={item.title} /> */
                                        <ReactImageMagnify
                                            {...{
                                                smallImage: {
                                                    alt: item.title,
                                                    src: item.img,
                                                    isFluidWidth: true,
                                                    hoverOffSetX: 0,
                                                    hoverOffSetY: 0,
                                                },
                                                largeImage: {
                                                    src: item.img,
                                                    width: 1920,
                                                    height: 1080,
                                                },
                                                enlargedImagePosition: "over",
                                                isActivatedOnTouch: false,
                                                isHintEnabled: true,
                                            }}
                                        />
                                    }
                                    {/* <ImageComponent image={item.img}/> */}
                                    <ImageListItemBar
                                        title={item.time}
                                        position="top"
                                        subtitle={`${snapshot.createReason}`}
                                        className={classes.titleBar}
                                        style={{ pointerEvents: "none" }}
                                    />
                                </ImageListItem>
                            ))
                            : null}
                    </ImageList>
                </CardContent>
                {/* <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions> */}
            </Card>
        </Box>
    );
};

export default Page;